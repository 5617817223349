export interface Stream {
  createdAt: string
  deletedAt?: string
  endedAt?: string
  liveAt?: string
  publishedAt?: string
  id: string
  title: string
  streamSource: string
  streamSourceId: string
  gameIds: string[]
  leaderboardIds: string[]
}

export interface StreamsResponse {
  streams: Stream[]
}

export const getStreams = (clientName: string) => {
  const url = `https://api.${clientName}.buffup.net`
  return fetch(`${url}/streams?pagination.pageSize=50`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json() as Promise<StreamsResponse>
    })
    .then((data) => {
      return data.streams
    })
}
