import check from 'assets/check.svg'
import styles from './BuffInfo.module.scss'

const ITEMS = [
  {
    id: 1,
    title: 'Buffs entertain',
    content:
      'Create a game within the game for a unique and fun fan experience.',
  },
  {
    id: 2,
    title: 'Buffs monetize',
    content:
      'Monetize the audience directly through transactions and sponsorship - Sport Buff creates innovative new ad inventory.',
  },
  {
    id: 3,
    title: 'Buffs retain',
    content:
      'Fans play along in real time directly on the broadcast watching much longer.',
  },
]

const BuffInfo = () => {
  return (
    <div>
      <h1 className={styles.title}>Sport Buff</h1>
      <p className={styles.subtitle}>
        Turn your broadcast into an interactive, gamified broadcast with
        predictions, trivia, ratings, and more real time interactions
      </p>
      {ITEMS.map((item) => {
        return (
          <div key={`item_${item.id}`} className={styles.item}>
            <img alt="checkmark" src={check} className={styles.icon} />
            <div className={styles.itemContent}>
              <h4>{item.title}</h4>
              <p>{item.content}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default BuffInfo
