export const BUFF_CONFIG = {
  containerQuerySelector: '#buff-video-container',
  streamId: 906,
  player: 'default',
  clientName: process.env.REACT_APP_CLIENT_NAME,
  // pluginType: 'default',
  // force: false,
  // debug: true,
  // ignoreRemoteConfig: false,
  // hideBuffFullscreenButton: false,
  // customization: {
  //   playSoundOnBuff: true,
  //   setDefaultVolume: true,
  //   webController: true,
  //   autoHideMenu: true,
  //   menuPosition: 'right',
  //   buffPosition: 'left',
  //   buffAnimationIn: 'bounceInUp',
  //   buffAnimationOut: 'bounceOutDown',
  //   menuXoffset: 5,
  //   menuYoffset: 5,
  //   buffXoffset: 5,
  //   buffYoffset: 5,
  // }
}

export const CUSTOM_PLAYER_CONFIG = {
  player: 'custom-functions',
  clientName: process.env.REACT_APP_CLIENT_NAME,
}
