import { FC, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import QR from 'qrcode'
import logo from 'assets/buff.png'
import styles from './QRCode.module.scss'

const QRCode: FC = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const qrcode = params?.get('qrcode')

  const qrCanvasRef = useRef<HTMLCanvasElement>(null)
  const showQRCodes = qrcode === 'true'

  useEffect(() => {
    const element = qrCanvasRef.current
    if (element && showQRCodes) {
      QR.toCanvas(
        element,
        window.location.href,
        { errorCorrectionLevel: 'M', width: element?.clientWidth },
        () => {}
      )
    }
  }, [window.location.href])

  if (!showQRCodes) return null

  return (
    <div className={styles.qrCode}>
      <img alt="logo" src={logo} />
      <canvas id="qr-canvas" ref={qrCanvasRef} />
    </div>
  )
}

export default QRCode
