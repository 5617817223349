import { FC, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import { StreamType } from 'components/Video/Video'
import { getStreams } from 'services/stream'
// import { ReactComponent as ShareIcon } from 'assets/share-button.svg'
// import pumaLogo from 'assets/puma.png'
import NoSleep from 'utils/noSleep'
import firebase from 'utils/firebase'
import { DEFAULT_REMOTE_CONFIG, REMOTE_FETCH_TIME } from 'config/Remote'
import { QRCode } from 'components/QRCode'

import styles from './LandingPage.module.scss'

interface Props {
  isPreStream?: boolean
  logo?: string
  bgImage?: string
  client?: string
}

// const TARGET = process.env.REACT_APP_TARGET

const noSleep = new NoSleep()

const remoteConfig = firebase.remoteConfig()
remoteConfig.settings = {
  fetchTimeoutMillis: REMOTE_FETCH_TIME,
  minimumFetchIntervalMillis: REMOTE_FETCH_TIME,
}

remoteConfig.defaultConfig = DEFAULT_REMOTE_CONFIG

const clientText: { [key: string]: any } = {
  'tv-2': {
    modalTitle: 'Klar for å delta?',
    modalText: `Registrer deg nå, svar på spørsmålene som dukker opp på skjermen,
    og klatre til topps på vår ledertavle!`,
    modalButton: 'Registrer deg / logg inn',
    bottomText: `Delta i våre konkurranser og vinn premier. Registrer en konto, svar
    på spørsmålene, og du kan vinne. Svar på spørsmålene etter hvert som
    vi sender dem ut i løpet av kvelden. Vi kontakter vinnerne etter at
    konkurransen er avsluttet.`,
  },
  sportbuff: {
    modalTitle: 'Enter email and win tickets',
    modalText:
      'Answer 6 questions for your chance to win tickets or a VIP upgrade.',
    modalButton: 'SIGN UP',
    bottomText: '',
  },
}

const LandingPage: FC<Props> = ({ isPreStream, logo, bgImage, client }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [width, setWidth] = useState(window.innerWidth)
  // const [_, setWidth] = useState<number>(window.innerWidth)
  const [wakeLock, setWakeLock] = useState(null)
  // const [game, setGame] = useState<string | null>(null)
  // const [gameDay, setGameDay] = useState<string | null>(null)
  // const [channel, setChannel] = useState<string | null>(null)
  const [streamId, setStreamId] = useState(params?.get('streamId') ?? null)
  const sourceId = params?.get('sourceId')
  const clientName = client ?? params?.get('clientName') ?? 'qa'
  const widgetInstance = useRef<any>()
  const sportBuffContainer = useRef<HTMLElement>()
  const container = useRef<HTMLDivElement>(null)
  const streamType =
    params?.get('isVOD') === 'true' ? StreamType.VOD : StreamType.LIVE
  const notify = () =>
    toast('', {
      duration: Infinity,
    })

  const getRemoteConfig = () => {
    return remoteConfig.fetchAndActivate().finally(() => {
      // const remoteOptions = JSON.parse(
      //   remoteConfig.getValue(`config_${TARGET}`).asString()
      // )
      // setGame(remoteOptions?.euroleague?.game)
      // setGameDay(remoteOptions?.euroleague?.date)
      // setChannel(remoteOptions?.euroleague?.channel)
    })
  }

  const onTabFocus = async () => {
    try {
      if (!wakeLock) {
        // @ts-ignore
        const lockObj = await navigator.wakeLock.request('screen')
        setWakeLock(lockObj as any)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onTabBlur = async () => {
    if (wakeLock) {
      // @ts-ignore
      await wakeLock.release()
      setWakeLock(null)
    }
  }

  const init = async (_config: any) => {
    if (!_config?.streamId && !_config?.streamSourceId) return

    const handleMouseOut = () => {
      widgetInstance?.current?.controls.hideUi()
    }

    const handleMouseOver = () => {
      widgetInstance?.current?.controls.showUi()
    }

    const appendContainer = (containerToAppend: HTMLElement) => {
      sportBuffContainer.current = containerToAppend
      container.current?.appendChild?.(containerToAppend)
    }

    const destroyContainer = () => {
      if (!sportBuffContainer.current) return
      container?.current?.removeChild?.(sportBuffContainer.current)

      container?.current?.removeEventListener('mouseover', handleMouseOver)
      container?.current?.removeEventListener('mouseout', handleMouseOut)
    }

    container?.current?.addEventListener('mouseover', handleMouseOver)
    container?.current?.addEventListener('mouseout', handleMouseOut)

    // TODO: should be reverted when SFX event is done
    const widgetConfig = {
      ..._config,
      streamId: null,
      streamSourceId: 'SFX6',
      appendContainer,
      destroyContainer,
    }

    const instance = await window?.SportBuff.init(widgetConfig)
    widgetInstance.current = instance
  }

  useEffect(() => {
    const script = document.createElement('script')

    const scriptUrl = ['develop', 'qa', 'sportbuff'].includes(clientName)
      ? `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/latest/bundle-main.min.js`
      : `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/5.x/bundle-main.min.js`
    script.setAttribute('src', scriptUrl)
    script.setAttribute('type', 'text/javascript')

    document.head.appendChild(script)

    setTimeout(() => {
      const token = localStorage.getItem('SPORT_BUFF_TOKEN')

      if (!token) notify()

      const buffContainer = document.getElementById('SportBuff-container')

      if (buffContainer) buffContainer.style.zIndex = '1000'
    }, 2000)

    document.addEventListener(
      'click',
      () => {
        noSleep.enable()
      },
      { once: true }
    )

    getRemoteConfig()

    return () => {
      window.removeEventListener('focus', onTabFocus)
      window.removeEventListener('blur', onTabBlur)
    }
  }, [])

  useEffect(() => {
    const fetchStreams = async () => {
      const streams = await getStreams(clientName)
      const firstLiveStream = await streams?.find((s) => {
        return streamType === StreamType.VOD
          ? s.endedAt && s.liveAt
          : !s.endedAt && s.liveAt
      })
      setStreamId(firstLiveStream?.id ?? '')
    }

    if (!streamId && !sourceId) fetchStreams()

    let config: any = {
      player: 'custom-functions',
      clientName,
    }

    if (sourceId && !streamId) {
      config = {
        ...config,
        streamSourceId: sourceId,
      }
    }

    if (!sourceId && streamId) {
      config = {
        ...config,
        streamId,
      }
    }

    if (isPreStream) {
      config = {
        ...config,
        preStreamBuffFrequency: 2,
      }
    }

    if (window.SportBuff) {
      if (widgetInstance.current) {
        widgetInstance.current.destroy()
      }

      setTimeout(() => {
        init(config)
      }, 200)
    } else {
      ;(window as any).onSportBuffReady = () => {
        init(config)
      }
    }

    const interval = setInterval(() => {
      const token = localStorage.getItem('SPORT_BUFF_TOKEN')

      if (token && toast) {
        toast.dismiss()
      }
    }, 2000)

    return () => clearInterval(interval)
  }, [streamId, sourceId])

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const handleClick = () => {
    const buff = document.getElementById('SportBuff-container')
    const shadow = buff?.shadowRoot
    const buffContainer = shadow?.lastChild
    const btn = (buffContainer as any).querySelector(
      'button[data-testid="points-deck-right-button"]'
    )

    btn.click()
  }

  // const share = () => {
  //   navigator.share({
  //     url: 'https://novasports.gr/play',
  //     text: 'Δοκίμασε τις γνώσεις σου στην Ευρωλίγκα. Παίξε μαζί μου!',
  //     title: 'Προσκαλέστε τους φίλους σας',
  //   })
  // }

  return (
    <>
      <div
        id="buff-video-container"
        className={styles.container}
        ref={container}
      >
        {logo && (
          <header className={styles.header}>
            <div className={styles.logo}>
              <img src={logo} alt="logo" />
            </div>
            {/* <div className={styles.share}>
            <button
              type="button"
              style={{
                fontSize: '0.8em',
              }}
              onClick={share}
            >
              <ShareIcon fill="#191c1f" style={{ marginRight: '0.8em' }} />
              Μοιραστείτε το παιχνίδι με τους φίλους σας!
            </button>
          </div> */}
          </header>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.2em',
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'left',
            backgroundPositionX: '-62px',
          }}
        >
          {/* ΑΠΟΚΛΕΙΣΤΙΚΑ ΣΤΟ NOVASPORTS */}
        </div>
        <div className={styles.footer}>
          {/* {game && (
            <div className={styles.nextGame}>
              <div className={styles.textContainer}>
                <div className={styles.title}>
                  <p>
                    <b>Επόμενο παιχνίδι:</b>
                  </p>
                  <p>{game}</p>
                  <p>{gameDay}</p>
                  <p>{channel}</p>
                </div>
              </div>
            </div>
          )}
          <div className={styles.sponsor}>
            <p style={{ fontSize: '0.75em' }}>Χορηγός</p>
            <img src={pumaLogo} alt="logo mobile" />
          </div>
          <div className={styles.terms}>
            <a
              href="https://buffup-public.prod.buffup.net/assets/novagr/legal.html"
              target="_blank"
              rel="noreferrer"
            >
              Όροι Συμμετοχής SportBuff
            </a>
          </div> */}
          <p className={styles.footerText}>
            {clientText[client as string].bottomText}
          </p>
        </div>
      </div>

      <QRCode />

      <Toaster
        position={width <= 768 ? 'bottom-center' : 'top-left'}
        containerClassName="toaster-container"
        containerStyle={{
          zIndex: 900,
          // marginTop: '5em',
          // marginLeft: '4em',
        }}
      >
        {(t) => (
          <div
            style={{
              ...t.style,
            }}
            className={`${styles.banner}`}
          >
            <div style={{ width: '100%', marginBottom: '3rem' }}>
              <h1 style={{ fontWeight: 700 }}>
                {clientText[client as string].modalTitle}
              </h1>
              {clientText[client as string].modalText}
            </div>
            <button type="button" onClick={handleClick}>
              {clientText[client as string].modalButton}
            </button>
          </div>
        )}
      </Toaster>
    </>
  )
}

export default LandingPage

LandingPage.defaultProps = {
  isPreStream: false,
  logo: undefined,
  bgImage: undefined,
  client: undefined,
}
