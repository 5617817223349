import { FC, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { StreamType } from 'components/Video/Video'
import { getStreams } from 'services/stream'
import mexicoShirt from 'assets/televisa1.png'
import boxBg from 'assets/rectangle.png'
import logo2 from 'assets/logo.png'

import styles from './Predict6.module.scss'

interface Props {
  logo?: string
  client?: string
}

const Predict6: FC<Props> = ({ logo, client }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [wakeLock, setWakeLock] = useState(null)
  const [streamId, setStreamId] = useState(params?.get('streamId') ?? null)
  const sourceId = params?.get('sourceId')
  const clientName = client ?? params?.get('clientName') ?? 'sportbuff'
  const isPreGameMode = params?.get('pregameMode') ?? false
  const preGameFrequency = params?.get('buffFrequency') ?? 10
  const anonymousLogin = params?.get('anonymousLogin') ?? false
  const token = params?.get('userToken') ?? null
  const widgetInstance = useRef<any>()
  const sportBuffContainer = useRef<HTMLElement>()
  const container = useRef<HTMLDivElement>(null)
  const streamType =
    params?.get('isVOD') === 'true' ? StreamType.VOD : StreamType.LIVE

  const onTabFocus = async () => {
    try {
      if (!wakeLock) {
        // @ts-ignore
        const lockObj = await navigator.wakeLock.request('screen')
        setWakeLock(lockObj as any)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onTabBlur = async () => {
    if (wakeLock) {
      // @ts-ignore
      await wakeLock.release()
      setWakeLock(null)
    }
  }

  const init = async (_config: any) => {
    if (!_config?.streamId && !_config?.streamSourceId) return

    const handleMouseOut = () => {
      widgetInstance?.current?.controls.hideUi()
    }

    const handleMouseOver = () => {
      widgetInstance?.current?.controls.showUi()
    }

    const appendContainer = (containerToAppend: HTMLElement) => {
      sportBuffContainer.current = containerToAppend
      container.current?.appendChild?.(containerToAppend)
    }

    const destroyContainer = () => {
      if (!sportBuffContainer.current) return
      container?.current?.removeChild?.(sportBuffContainer.current)

      container?.current?.removeEventListener('mouseover', handleMouseOver)
      container?.current?.removeEventListener('mouseout', handleMouseOut)
    }

    container?.current?.addEventListener('mouseover', handleMouseOver)
    container?.current?.addEventListener('mouseout', handleMouseOut)

    const widgetConfig = {
      ..._config,
      appendContainer,
      destroyContainer,
    }

    const instance = await window?.SportBuff.init(widgetConfig)
    widgetInstance.current = instance
  }

  useEffect(() => {
    const script = document.createElement('script')

    const scriptUrl = ['develop', 'qa', 'sportbuff'].includes(clientName)
      ? `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/latest/bundle-main.min.js`
      : `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/5.x/bundle-main.min.js`
    script.setAttribute('src', scriptUrl)
    script.setAttribute('type', 'text/javascript')

    document.head.appendChild(script)

    setTimeout(() => {
      const buffContainer = document.getElementById('SportBuff-container')
      if (buffContainer) buffContainer.style.zIndex = '1000'
    }, 2000)

    return () => {
      window.removeEventListener('focus', onTabFocus)
      window.removeEventListener('blur', onTabBlur)
    }
  }, [])

  useEffect(() => {
    const fetchStreams = async () => {
      const streams = await getStreams(clientName)
      const firstLiveStream = await streams?.find((s) => {
        return streamType === StreamType.VOD
          ? s.endedAt && s.liveAt
          : !s.endedAt && s.liveAt
      })
      setStreamId(firstLiveStream?.id ?? '')
    }

    if (!streamId && !sourceId) fetchStreams()

    let config: any = {
      player: 'custom-functions',
      clientName,
    }

    if (token) {
      config = {
        ...config,
        token,
      }
    }

    if (sourceId && !streamId) {
      config = {
        ...config,
        streamSourceId: sourceId,
      }
    }

    if (!sourceId && streamId) {
      config = {
        ...config,
        streamId,
      }
    }

    if (isPreGameMode) {
      config = {
        ...config,
        preStreamBuffFrequency: preGameFrequency,
        anonymousLogin,
      }
    }

    if (window.SportBuff) {
      if (widgetInstance.current) {
        widgetInstance.current.destroy()
      }

      setTimeout(() => {
        init(config)
      }, 200)
    } else {
      ;(window as any).onSportBuffReady = () => {
        init(config)
      }
    }
  }, [streamId, sourceId])

  function handleWindowSizeChange() {
    // setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return (
    <>
      <div
        id="buff-video-container"
        className={styles.container}
        ref={container}
      >
        <header className={styles.header}>
          <div className={styles.logo}>
            <img
              src={logo}
              alt="logo"
              style={{ position: 'relative', top: '18px' }}
            />
          </div>
          <svg
            className={styles.logo2}
            width="64"
            height="88"
            viewBox="0 0 64 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32.7026 88C26.5709 88 21.0353 86.5525 16.0958 83.6575C11.1564 80.7625 7.23886 76.5051 4.34331 70.8853C1.44777 65.2656 0 58.4112 0 50.3222C0 45.2985 0.936793 40.4451 2.81038 35.762C4.76913 30.9937 7.40918 26.4809 10.7305 22.2235C14.1371 17.881 18.0546 13.8365 22.483 10.09C26.9115 6.34349 31.6381 2.98017 36.6627 0L49.0539 13.4107C42.2408 16.0503 36.6627 19.4562 32.3194 23.6285C27.976 27.7155 24.7824 31.9729 22.7385 36.4006C20.6946 40.8283 19.6727 44.9153 19.6727 48.6618L15.0739 48.1509C17.3733 42.7866 20.3114 38.6144 23.8882 35.6343C27.5502 32.6541 32.6174 31.164 39.0898 31.164C43.1776 31.164 47.1377 32.3135 50.9701 34.6125C54.8024 36.9115 57.9108 40.1045 60.2954 44.1916C62.7651 48.2787 64 53.0469 64 58.4964C64 64.7973 62.4671 70.1616 59.4012 74.5893C56.4205 78.9318 52.5456 82.2525 47.7764 84.5515C43.0073 86.8505 37.9827 88 32.7026 88ZM31.9361 71.3962C34.4059 71.3962 36.5775 70.9279 38.4511 69.9913C40.4098 68.9695 41.9428 67.522 43.0499 65.6488C44.157 63.6904 44.7106 61.3488 44.7106 58.6241C44.7106 55.9845 44.1144 53.8133 42.9222 52.1103C41.815 50.3222 40.2821 49.0024 38.3233 48.1509C36.4498 47.2143 34.3207 46.746 31.9361 46.746C29.5516 46.746 27.3799 47.2143 25.4212 48.1509C23.5476 49.0024 22.0146 50.1945 20.8224 51.7271C19.7152 53.1746 19.1617 54.7499 19.1617 56.4528V59.5181C19.1617 61.5617 19.7152 63.4775 20.8224 65.2656C21.9295 67.0537 23.4624 68.5438 25.4212 69.7358C27.3799 70.8428 29.5516 71.3962 31.9361 71.3962Z"
              fill="#0A5E45"
            />
          </svg>
          <div className={styles.text1}>PREDICT6</div>
          <div>
            <img
              src={logo2}
              alt="logo"
              style={{ position: 'absolute', top: '38px', right: '18px' }}
            />
          </div>
        </header>
        <div
          style={{
            display: 'flex',
            height: '343px',
            width: '100%',
            position: 'absolute',
            alignSelf: 'center',
            top: '110px',
            left: '0px',
            zIndex: '100',
          }}
        >
          <h2
            style={{
              color: 'white',
              fontWeight: 700,
              width: '100%',
              textAlign: 'center',
              alignSelf: 'center',
              position: 'relative',
              top: '23px',
              left: '7px',
              zIndex: '1001',
            }}
          >
            WIN A SIGNED
            <br /> MEXICO SHIRT
          </h2>
          <div
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              top: '0px',
              left: '0px',
              backgroundImage: `url(${mexicoShirt})`,
              maskImage:
                ' linear-gradient(180deg, white 44%, rgba(115, 115, 115, 0) 100%)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          />
        </div>
        {/* <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.2em',
          }}
        /> */}
        {/* <div className={styles.footer}>
          <div
            className={styles.bgBox}
            style={{
              backgroundImage: `url(${boxBg})`,
            }}
          />
        </div> */}
      </div>
    </>
  )
}

export default Predict6

Predict6.defaultProps = {
  logo: undefined,
  client: undefined,
}
