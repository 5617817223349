import { FC, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import firebase from 'firebase'
import { DEFAULT_REMOTE_CONFIG, REMOTE_FETCH_TIME } from 'config/Remote'
import { getStreams } from 'services/stream'
import Video, { StreamType } from 'components/Video/Video'
// import NoVideo from 'components/NoVideo/NoVideo'
import { BuffInfo } from 'components/BuffInfo'
import { QRCode } from 'components/QRCode'

import styles from './PageTemplate.module.scss'

const remoteConfig = firebase.remoteConfig()
remoteConfig.settings = {
  fetchTimeoutMillis: REMOTE_FETCH_TIME,
  minimumFetchIntervalMillis: REMOTE_FETCH_TIME,
}

remoteConfig.defaultConfig = DEFAULT_REMOTE_CONFIG

interface Props {
  isPlaylistMode?: boolean
  isTvMode?: boolean
  isTimeSync?: boolean
  fixedPortraitBuffs?: boolean
}

const contentBlock = (
  <div className={styles.contentBlock}>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla faucibus
      sit amet turpis ac aliquet.
    </p>
    <p>Sed varius mauris eget purus tincidunt, at lobortis lacus varius. </p>
    <p>
      Aliquam vel posuere est. Vivamus egestas diam eu ligula consectetur
      tincidunt.
    </p>
    <p>
      {' '}
      Etiam eget urna posuere, dictum lorem ac, ullamcorper nisl. Donec nisi
      est, convallis eget sem in, hendrerit iaculis quam.
    </p>
    <p>
      {' '}
      Nunc vitae velit id dolor rhoncus aliquam et in neque. Nullam maximus
      blandit nisi, id ornare diam convallis quis.{' '}
    </p>
    <p>
      {' '}
      Vestibulum convallis est non cursus congue. Vestibulum vestibulum ac dolor
      vitae lacinia.{' '}
    </p>
    <p>
      {' '}
      Vivamus sodales lacus porta tincidunt tincidunt. Duis tincidunt
      sollicitudin elit, vitae consectetur nulla efficitur vitae.
    </p>
    <p>
      {' '}
      Aliquam auctor arcu sit amet quam ullamcorper dapibus. Nunc et mauris at
      massa tempus dapibus sed at risus.{' '}
    </p>
    <p> Proin aliquet dui sem, quis efficitur ligula elementum laoreet.</p>
  </div>
)

const PageTemplate: FC<Props> = ({
  isPlaylistMode,
  isTvMode,
  isTimeSync,
  fixedPortraitBuffs,
}) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [streamId, setStreamId] = useState(params?.get('streamId') ?? null)
  const clientName = params?.get('clientName') ?? 'qa'
  const delay = params?.get('delay') ?? '10'
  const tvDeviceId = params?.get('tvDeviceId')
  const widgetInstance = useRef<any>()
  const sportBuffContainer = useRef<HTMLElement>()
  const container = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoUrl = `https://buffup-public.prod.buffup.net/video/${
    params?.get('videoId') ?? 'FIFA_VOD_SHORT'
  }.${params?.get('videoExt') ?? 'mp4'}`
  const streamType =
    params?.get('isVOD') === 'true' ? StreamType.VOD : StreamType.LIVE

  const handleTimeUpdate = () => {
    widgetInstance?.current?.controls?.updateTimeVOD(
      videoRef?.current?.currentTime || 0
    )
  }

  const init = async (_config: any) => {
    if (!_config?.streamId) return

    const handleMouseOut = () => {
      widgetInstance?.current?.controls.hideUi()
    }

    const handleMouseOver = () => {
      widgetInstance?.current?.controls.showUi()
    }

    const appendContainer = (containerToAppend: HTMLElement) => {
      sportBuffContainer.current = containerToAppend
      container.current?.appendChild?.(containerToAppend)
    }

    const destroyContainer = () => {
      if (!sportBuffContainer.current) return
      container?.current?.removeChild?.(sportBuffContainer.current)

      container?.current?.removeEventListener('mouseover', handleMouseOver)
      container?.current?.removeEventListener('mouseout', handleMouseOut)
    }

    container?.current?.addEventListener('mouseover', handleMouseOver)
    container?.current?.addEventListener('mouseout', handleMouseOut)

    const widgetConfig = {
      ..._config,
      appendContainer,
      destroyContainer,
      addFullScreenButton: true,
      fullScreenElement: container.current,
    }

    const widgetConfigPlaylist = {
      ..._config,
      appendContainer,
      destroyContainer,
    }

    const widgetConfigTV = {
      ..._config,
      appendContainer,
      destroyContainer,
      tvDeviceId,
      qrCodeVisibleDuration: 10,
      qrCodeVisibleFrequency: 1,
    }

    let config = widgetConfig

    if (isPlaylistMode) {
      config = widgetConfigPlaylist
    }

    if (isTvMode) {
      config = widgetConfigTV
    }

    const instance = await window?.SportBuff.init(config)
    widgetInstance.current = instance

    if (isTimeSync) {
      setInterval(() => {
        const date = new Date()
        date.setSeconds(date.getSeconds() - parseInt(delay, 10))
        instance.controls.updateTimeTimeSync(date.getTime())
      }, 1000)
    }
  }

  useEffect(() => {
    videoRef?.current?.addEventListener('timeupdate', handleTimeUpdate)
  }, [videoRef.current])

  useEffect(() => {
    const script = document.createElement('script')
    let scriptUrl = ['develop', 'qa', 'sportbuff'].includes(clientName)
      ? `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/latest/bundle-main.min.js`
      : `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/5.x/bundle-main.min.js`

    if (fixedPortraitBuffs) {
      scriptUrl =
        'https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/develop/latest/bundle-main.min.js'
    }

    script.setAttribute('src', scriptUrl)
    script.setAttribute('type', 'text/javascript')

    document.head.appendChild(script)
  }, [])

  useEffect(() => {
    const fetchStream = async () => {
      const streams = await getStreams(clientName)
      const firstLiveStream = await streams?.find((s) => {
        return streamType === StreamType.VOD
          ? s.endedAt && s.liveAt
          : !s.endedAt && s.liveAt
      })
      setStreamId(firstLiveStream?.id ?? '')
    }

    if (!streamId) fetchStream()

    const config = {
      player: 'custom-functions',
      streamId,
      clientName,
      fixedPortraitBuffs,
    }

    if (window.SportBuff) {
      if (widgetInstance.current) {
        widgetInstance.current.destroy()
      }

      setTimeout(() => {
        init(config)
      }, 200)
    } else {
      ;(window as any).onSportBuffReady = () => {
        init(config)
      }
    }
  }, [streamId])

  return (
    <div style={{}}>
      {isPlaylistMode && (
        <section className={styles.container}>
          <div className={styles.leftSide}>
            <div
              id="buff-video-container"
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
              ref={container}
            >
              <BuffInfo />
            </div>
          </div>
          <div className={styles.rightSide}>
            {contentBlock}
            {contentBlock}
            {contentBlock}
            {contentBlock}
            {contentBlock}
            {contentBlock}
          </div>
        </section>
      )}
      {!isPlaylistMode && (
        <div
          id="buff-video-container"
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
          ref={container}
        >
          <Video ref={videoRef} streamType={streamType} videoUrl={videoUrl} />
        </div>
      )}

      <QRCode />
    </div>
  )
}

export default PageTemplate

PageTemplate.defaultProps = {
  isPlaylistMode: false,
  isTvMode: false,
  isTimeSync: false,
  fixedPortraitBuffs: false,
}
