export const REMOTE_FETCH_TIME = 300000

export const DEFAULT_REMOTE_CONFIG = {
  config_NEWDEV: `{
    "LIVE": {
      "video_url": "https://hls-test.buffup.net/sdkdemo/main.m3u8",
      "stream_id": 906,
      "poster": {"url": ""}
    },
    "TWITCH": {
      "stream_id": 175,
      "channel": "buffupltd",
      "poster": {
        "url": ""
      }
    },
    "VOD": {
      "hAr9Av4": {
          "video_url": "https://buffup-public.s3.eu-west-2.amazonaws.com/video/Rugby+Demo.mp4",
          "stream_id": 174,
          "poster": {"url": ""}
      },
      "r11a0Sw": {
          "video_url": "https://buffup-public.s3.eu-west-2.amazonaws.com/video/ATA+Blank+DEMO_5min.mp4",
          "stream_id": 174,
          "poster": {"url": ""}
      },
      "5JR61Jg": {
          "video_url": "https://buffup-public.s3.eu-west-2.amazonaws.com/video/Auto+Buff+Stevie+G.mp4",
          "stream_id": 174,
          "poster": {"url": ""}
      }
    }
  }`,
  config_DEVELOPMENT: `{
      "LIVE": {
        "video_url": "https://hls-test.buffup.net/sdkdemo/main.m3u8",
        "stream_id": 906,
        "poster": {"url": ""}
      },
      "TWITCH": {
        "stream_id": 175,
        "channel": "buffupltd",
        "poster": {
          "url": ""
        }
      },
      "VOD": {
        "hAr9Av4": {
            "video_url": "https://buffup-public.s3.eu-west-2.amazonaws.com/video/Rugby+Demo.mp4",
            "stream_id": 174,
            "poster": {"url": ""}
        },
        "r11a0Sw": {
            "video_url": "https://buffup-public.s3.eu-west-2.amazonaws.com/video/ATA+Blank+DEMO_5min.mp4",
            "stream_id": 174,
            "poster": {"url": ""}
        },
        "5JR61Jg": {
            "video_url": "https://buffup-public.s3.eu-west-2.amazonaws.com/video/Auto+Buff+Stevie+G.mp4",
            "stream_id": 174,
            "poster": {"url": ""}
        }
      }
    }`,
  config_RELEASE: `{
      "LIVE": {
        "video_url": "https://hls-test.buffup.net/sdkdemo/main.m3u8",
        "stream_id": 147,
        "poster": {"url": ""}
      },
      "TWITCH": {
        "stream_id": 147,
        "channel": "buffupltd",
        "poster": {
          "url": ""
        }
      },
      "VOD": {
        "hAr9Av4": {
            "video_url": "https://buffup-public.s3.eu-west-2.amazonaws.com/video/Rugby+Demo.mp4",
            "stream_id": 147,
            "poster": {"url": ""}
          },
        "r11a0Sw": {
            "video_url": "https://buffup-public.s3.eu-west-2.amazonaws.com/video/ATA+Blank+DEMO_5min.mp4",
            "stream_id": 147,
            "poster": {"url": ""}
        },
        "5JR61Jg": {
            "video_url": "https://buffup-public.s3.eu-west-2.amazonaws.com/video/Auto+Buff+Stevie+G.mp4",
            "stream_id": 147,
            "poster": {"url": ""}
        }
      }
    }`,
}
