import Centrifuge from 'centrifuge'
import Routes from 'config/Router'

declare global {
  interface Window {
    BuffVideo: any
    SportBuff: any
    videojs: any
  }
}

const App = () => {
  ;(window as any).SB_centrifugo_debug = (channel: string, client = 'qa') => {
    const centrifugoURI = `wss://app-centrifugo.api.${client}.buffup.net/connection/websocket`
    const centrifuge = new Centrifuge(centrifugoURI, {
      debug: true,
    })

    centrifuge.on('connect', function (ctx) {
      console.log('Connected to channel', channel)
      console.log('ctx', ctx)
    })

    centrifuge.connect()

    centrifuge.subscribe(channel, (message) => {
      console.log('message', message)
    })
  }

  return <Routes />
}

export default App
