import { FC } from 'react'
import firebase from 'utils/firebase'

import { Header } from 'components/Header'
import { PageTemplate } from 'components/PageTemplate'
import { REMOTE_FETCH_TIME, DEFAULT_REMOTE_CONFIG } from 'config/Remote'

import styles from './Home.module.scss'

const remoteConfig = firebase.remoteConfig()
remoteConfig.settings = {
  fetchTimeoutMillis: REMOTE_FETCH_TIME,
  minimumFetchIntervalMillis: REMOTE_FETCH_TIME,
}

remoteConfig.defaultConfig = DEFAULT_REMOTE_CONFIG

interface Props {
  isPlaylistMode?: boolean
  isTvMode?: boolean
  isTimeSync?: boolean
  fixedPortraitBuffs?: boolean
}

const Home: FC<Props> = ({
  isPlaylistMode,
  isTvMode,
  isTimeSync,
  fixedPortraitBuffs,
}) => {
  return (
    <div>
      <Header />
      <div className={styles.content}>
        <PageTemplate
          isPlaylistMode={isPlaylistMode}
          isTvMode={isTvMode}
          isTimeSync={isTimeSync}
          fixedPortraitBuffs={fixedPortraitBuffs}
        />
      </div>
    </div>
  )
}

Home.defaultProps = {
  isPlaylistMode: false,
  isTvMode: false,
  isTimeSync: false,
  fixedPortraitBuffs: false,
}

export default Home
